import * as React from 'react';
import { Select, Tabs, message } from 'antd';
const TabPane = Tabs.TabPane;
import { helpers } from '@/utils';
const Option = Select.Option;
import callStyle from './callStyle.scss';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import * as _ from 'lodash';
import PostRemark from './postRemark';
import RemarkList from './remarkList';
import { setCallStartTime, setCallEndTime } from '@/actions/callAction';
import { multiPlatform } from '@/multiPlatform';
export default class Call extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      callStartTime: -1, // 电话接通开始时间
      callEndTime: -1, // 电话接通结束时间
      second: -1,
      username: '', // 用户名
      real_name: '', // 真实名称
      register_time: '', // 注册时间
      last_deposit_amount: '', // 最后存款金额
      phone: '', // 电话号
      deposit_count: 0, // 存款次数
      isShowPhoneRelated: false, // 是否显示电话相关
      extensionState: -1, // 坐席状态
      isHungUp: false, // 挂线按钮的状态
      isMute: false, // 是否静音
      showRemarkList: false, // 显示回访备注
    };
  }

  // 备注列表反馈页面组件
  public RemarkListComponent = multiPlatform.remarkListMulti(RemarkList);
  // 添加备注反馈页面组件
  public PostRemarkComponent = multiPlatform.postRemarkMulti(PostRemark);

  public phone_line = parseInt(sessionStorage.getItem('phone_line') + '', 10); // 电话类型

  // 时间interval
  public timeInterval;

  public componentWillMount() {
    // 获取客户详细
    this.getCustomersDetails();

    // 设置结束时间
    this.setEndTime();
  }

  // 清除记时
  public componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  // 获取客户详细
  public getCustomersDetails = () => {
    this.props.dispatch({
      type: 'customers/getCustomersDetailsEpic',
      payload: {
        id: this.props.match.params.id,
      },
    });
  };

  // 设置结束时间
  public setEndTime = () => {
    const callStartTime = parseInt(
      localStorage.getItem('callStartTime') + '',
      10,
    );
    const callEndTime = parseInt(localStorage.getItem('callEndTime') + '', 10);
    this.props.dispatch(setCallStartTime(callStartTime));
    this.props.dispatch(setCallEndTime(callEndTime));

    clearInterval(this.timeInterval);
    this.timeInterval = setInterval(() => {
      if (this.phone_line === 1) {
        if (
          this.state.extensionState === 2 ||
          this.state.extensionState === 11
        ) {
          const endTime = new Date().getTime();
          localStorage.setItem('callEndTime', endTime + '');
          this.props.dispatch(setCallEndTime(endTime));
        }
      } else {
        if (this.state.extensionState === 99) {
          const endTime = new Date().getTime();
          localStorage.setItem('callEndTime', endTime + '');
          this.props.dispatch(setCallEndTime(endTime));
        }
      }
    }, 1000);
  };

  public componentWillReceiveProps(nextProps) {
    if (nextProps.all.customers.customersDetails !== {}) {
      this.setState({
        username: nextProps.all.customers.customersDetails.username, // 用户名
        real_name: nextProps.all.customers.customersDetails.x_real_name, // 真实名称
        register_time: nextProps.all.customers.customersDetails.register_time, // 注册时间
        last_deposit_amount:
          nextProps.all.customers.customersDetails.last_deposit_amount, // 最后存款金额
        phone: nextProps.all.customers.customersDetails.x_phone, // 电话号
        deposit_count: nextProps.all.customers.customersDetails.deposit_count, // 存款次数
      });
    }

    // 绑定坐席状态
    if (_.get(nextProps.all.agent.agentInfo, 'extensionState') != null) {
      const extensionState = nextProps.all.agent.agentInfo.extensionState;

      if (this.phone_line === 1) {
        // ploylink
        if (
          extensionState === 1 ||
          extensionState === 2 ||
          extensionState === 3 ||
          extensionState === 5 ||
          extensionState === 8 ||
          extensionState === 10 ||
          extensionState === 11
        ) {
          this.setState({
            isShowPhoneRelated: true,
            extensionState,
          });
        } else {
          this.setState({
            isShowPhoneRelated: false,
            extensionState,
          });
        }

        // 如果状态为静音
        this.setState({
          isMute: extensionState === 11,
        });
      } else {
        // 米糠云
        if (
          extensionState === 1 ||
          extensionState === 2 ||
          extensionState === 8 ||
          extensionState === 16 ||
          extensionState === 99
        ) {
          this.setState({
            isShowPhoneRelated: true,
            extensionState,
          });
        } else {
          this.setState({
            isShowPhoneRelated: false,
            extensionState,
          });
        }
      }

      if (this.phone_line === 1) {
        // 绑定通话开始时间
        if (extensionState === 2 && this.state.callStartTime !== -1) {
          this.setState({
            callStartTime: nextProps.all.callCenter.callStartTime,
          });
        }

        // 当为后处理取消秒数变化
        if (extensionState === 4) {
          clearInterval(this.timeInterval);
        }
      } else {
        // 绑定通话开始时间
        if (extensionState === 99 && this.state.callStartTime !== -1) {
          this.setState({
            callStartTime: nextProps.all.callCenter.callStartTime,
          });
        }

        // 当为后处理取消秒数变化
        if (
          localStorage.getItem('callingState') === '' &&
          extensionState === 0 &&
          this.state.callStartTime !== -1
        ) {
          clearInterval(this.timeInterval);
        }
      }

      // 设置秒数
      if (
        nextProps.all.callCenter.callStartTime !== -1 &&
        nextProps.all.callCenter.callEndTime !== -1
      ) {
        const second =
          nextProps.all.callCenter.callEndTime -
          nextProps.all.callCenter.callStartTime;
        this.setState({ second: Math.floor(second / 1000) });
      }
    }

    // 获取坐席号
    this.agentNum = _.get(
      nextProps.all.main.userInfo.polylink_account,
      'employee_id',
    );
  }

  // 挂电话
  public handleHungUp = () => {
    if (this.phone_line === 1 && this.state.extensionState === 11) {
      message.error('请关闭静音后挂机');
      return;
    }

    this.props.dispatch({
      type: 'callCenter/hungUpEpic',
      payload: {
        relationUuid: localStorage.getItem('relationUuid'),
        agentUuid: localStorage.getItem('agentUuid'),
      },
    });

    this.setState({ isHungUp: true });
  };

  // 坐席号
  public agentNum;

  // 是否静音 或者 是否保持
  public handleIsMute = isMute => {
    if (this.phone_line === 1) {
      if (isMute) {
        // 启动静音
        this.props.dispatch({
          type: 'callCenter/muteVoiceEpic',
          payload: {
            agentNum: this.agentNum,
            agentUuid: localStorage.getItem('agentUuid'),
          },
        });
      } else {
        // 取消静音
        this.props.dispatch({
          type: 'callCenter/unmuteVoiceEpic',
          payload: {
            agentNum: this.agentNum,
            agentUuid: localStorage.getItem('agentUuid'),
          },
        });
      }
    } else {
      // 保持或取消
      this.props.dispatch({
        type: 'callCenter/hold',
        payload: {},
      });
    }
  };

  // 渲染打电话操作
  public renderCallOperating = () => {
    if (!this.state.isShowPhoneRelated) {
      return null;
    }

    return (
      <div className={callStyle.right}>
        <p>
          <Select placeholder={'请选择添加谁'} allowClear>
            <Option key={'1'}>1</Option>
          </Select>
          <img src={require('./img/btn01.jpg')} />
          {helpers.isJudge(this.state.isMute)(
            helpers.isJudge(this.phone_line === 1)(
              <img
                src={require('./img/btn02d.png')}
                onClick={() => this.handleIsMute(false)}
                className={callStyle.shadow}
              />,
              <img
                src={require('./img/maintainOpen.png')}
                onClick={() => this.handleIsMute(false)}
                className={callStyle.shadow}
              />,
            ),
            helpers.isJudge(this.phone_line === 1)(
              <img
                src={require('./img/btn02.png')}
                onClick={() => this.handleIsMute(true)}
                className={callStyle.shadow}
              />,
              <img
                src={require('./img/maintainOpen.png')}
                onClick={() => this.handleIsMute(true)}
                className={callStyle.shadow}
              />,
            ),
          )}
        </p>
        <p>
          <Select placeholder={'请选择转接给谁'} allowClear>
            <Option key={'1'}>1</Option>
          </Select>
          <img src={require('./img/btn03.jpg')} />
          {helpers.isJudge(this.state.isHungUp)(
            <img src={require('./img/btn04d.png')} />,
            <img
              src={require('./img/btn04.png')}
              onClick={() => this.handleHungUp()}
            />,
          )}
        </p>
      </div>
    );
  };

  // 渲染电话状态
  public renderCallState = () => {
    if (this.phone_line === 1) {
      if (
        this.state.extensionState === 2 ||
        this.state.extensionState === 3 ||
        this.state.extensionState === 11
      ) {
        return (
          <h4>
            通话中 <span>{helpers.secondToTime(this.state.second)}</span>
          </h4>
        );
      } else if (this.state.extensionState === 4) {
        return (
          <h4>
            通话已结束 <span>{helpers.secondToTime(this.state.second)}</span>
          </h4>
        );
      } else {
        return <div style={{ height: 20 }} />;
      }
    } else {
      if (
        this.state.extensionState === 99 ||
        this.state.extensionState === 16
      ) {
        return (
          <h4>
            通话中 <span>{helpers.secondToTime(this.state.second)}</span>
          </h4>
        );
      } else {
        return <div style={{ height: 20 }} />;
      }
    }
  };

  // 渲染介绍部分
  public renderIntroduction = () => {
    return (
      <div className={callStyle.left}>
        {this.renderCallState()}
        <span>
          <span> 用户名：</span>
          {this.state.username}
        </span>
        <span style={{ width: '235px' }}>
          <span>手机号码：</span>
          {this.state.phone}
        </span>
        <span>
          <span>存款次数：</span>
          {this.state.deposit_count}次
        </span>
        <span>
          <span>真实姓名：</span> {this.state.real_name}
        </span>
        <span style={{ width: '235px' }}>
          <span> 注册时间：</span>
          {helpers.isJudge(
            (this.state.register_time + '').slice(0, 4) === '0000',
          )(null, this.state.register_time)}
        </span>
        <span>
          <span>最近存入：</span>
          {this.state.last_deposit_amount}
        </span>
      </div>
    );
  };

  public render() {
    const PostRemarkComponent = this.PostRemarkComponent;
    const RemarkListComponent = this.RemarkListComponent;

    return (
      <React.Fragment>
        <div className={callStyle.block1}>
          {this.renderIntroduction()}
          {this.renderCallOperating()}
        </div>
        <div className={callStyle.content}>
          <Tabs
            type={'card'}
            onChange={e => {
              this.setState({ showRemarkList: e === '2' });
            }}
          >
            <TabPane tab={'回访备注'} key={'1'}>
              <PostRemarkComponent
                tagName={this.props.match.params.type}
                customerId={this.props.match.params.id}
                usersCustomersId={this.props.match.params.relationshipId}
              />
            </TabPane>
            <TabPane tab={'历史回访备注'} key={'2'}>
              {helpers.isJudge(this.state.showRemarkList)(
                <RemarkListComponent id={this.props.match.params.id} />,
                null,
              )}
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
