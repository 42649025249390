import * as React from 'react';
import { history } from '@/router';
import {
  Select,
  DatePicker,
  Table,
  Button,
  Input,
  Icon,
  Pagination,
  Modal,
  message,
  Popover,
} from 'antd';
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;
import { helpers } from '@/utils';
const Option = Select.Option;
import { Alert1, XList } from '@/components';
import moment from 'moment';

import SendSms from '../sendSms/sendSmsPage';
import { multiPlatform } from '@/multiPlatform';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import * as _ from 'lodash';
import { binarySearchByKey } from '@/utils/binarySearch';
import Tasks, { MarketAccountType } from '@/routes/callCenter/tasks/tasksPage';
export default class BlockedTasks extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  public columns: any = [
    {
      title: '用户名',
      dataIndex: '用户名',
      width: 250,
      render: (text, record) => (
        <span className={'greenPointCell'}>
          {helpers.isJudge(record.obstruct_reason_handled === 1)(
            <span />,
            null,
          )}
          {_.get(record.customers, 'username')}
        </span>
      ),
    },
    {
      title: '真实姓名',
      dataIndex: 'x_real_name',
      align: 'center',
      width: 150,
      render: (text, record) =>
        record.customers ? record.customers.x_real_name : '--',
    },
    {
      title: '手机号码',
      dataIndex: 'x_phone',
      width: 200,
      align: 'center',
      minWidth: 180,
      render: (text, record) => (
        <p className={'phone'}>
          {record.customers.x_phone}{' '}
          {helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 93),
          )(
            <Icon
              type={'mail'}
              theme={'filled'}
              style={{ fontSize: '18px', marginLeft: 15, cursor: 'pointer' }}
              onClick={() =>
                this.setState({
                  showTimes: this.state.showTimes + 1,
                  customer_id: record.customer_id,
                  obstruct_id: record.id,
                })
              }
            />,
            null,
          )}{' '}
          {helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 92),
          )(
            helpers.isJudge(this.state.extensionState === 0)(
              <Icon
                type={'phone'}
                theme={'filled'}
                style={{ fontSize: '18px', marginLeft: 15, cursor: 'pointer' }}
                onClick={() => this.call(record.customer_id, record.id)}
              />,
              <Icon
                type={'phone'}
                theme={'filled'}
                style={{
                  fontSize: '18px',
                  marginLeft: 15,
                  cursor: 'pointer',
                  color: '#6c7c8e',
                }}
                onClick={() => message.warning('当前状态，无法呼叫')}
              />,
            ),
            null,
          )}
        </p>
      ),
    },
    {
      title: '注册时间',
      dataIndex: 'register_time',
      align: 'center',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(
          _.get(record.customers, 'register_time').slice(0, 10) ===
            '0000-00-00',
        )(
          null,
          <time>
            {_.get(record.customers, 'register_time').slice(0, 10)}
            <br />
            {_.get(record.customers, 'register_time').slice(10)}
          </time>,
        ),
    },
    {
      title: '最近存款时间',
      dataIndex: 'customers.last_deposit_time',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(
          _.get(record.customers, 'last_deposit_time').slice(0, 10) ===
            '0000-00-00',
        )(
          null,
          <time>
            {_.get(record.customers, 'last_deposit_time').slice(0, 10)}
            <br />
            {_.get(record.customers, 'last_deposit_time').slice(10)}
          </time>,
        ),
    },
    {
      title: '最近提款时间',
      dataIndex: 'customers.last_withdraw_time',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(
          _.get(record.customers, 'last_withdraw_time').slice(0, 10) ===
            '0000-00-00',
        )(
          null,
          <time>
            {_.get(record.customers, 'last_withdraw_time').slice(0, 10)}
            <br />
            {_.get(record.customers, 'last_withdraw_time').slice(10)}
          </time>,
        ),
    },
    {
      title: '受阻原因',
      dataIndex: '受阻原因',
      width: 200,
      render: (text, record) =>
        helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 95))(
          <Select
            placeholder={'请选择'}
            defaultValue={helpers.isJudge(record.obstruct_reason === 0)(
              undefined,
              record.obstruct_reason,
            )}
            onChange={(value) =>
              this.updateBlocked(record.id, { obstruct_reason: value })
            }
          >
            {this.obstructReasonArray.map((item) => {
              return <Option value={item.value}>{item.text}</Option>;
            })}
          </Select>,
          null,
        ),
    },
    {
      title: '受阻时间',
      dataIndex: 'obstructed_at',
      width: 150,
      render: (text) =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
    {
      title: '回访备注',
      dataIndex: '回访备注',
      align: 'center',
      width: 150,
      minWidth: 150,
      render: (text, record) => {
        // 第一条备注
        const [firstNote] = record.call_notes || [undefined];
        const placeholder = firstNote ? (
          <Popover content={<span>{firstNote.note}</span>}>
            <span
              style={{
                maxWidth: 'calc(100% - 30px)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '1em',
                verticalAlign: 'inherit',
                display: 'inline-block',
              }}
            >
              {firstNote.note}
            </span>
          </Popover>
        ) : null;
        return helpers.isJudge(
          _.includes(this.props.all.main.buttonPermission, 94),
        )(
          <React.Fragment>
            {placeholder}
            <a
              href={'javascript:;'}
              onClick={() => {
                history.push(
                  history.location.pathname +
                    '/' +
                    record.customer_id +
                    '/' +
                    record.id,
                );
              }}
            >
              全部
            </a>
          </React.Fragment>,
          null,
        );
      },
    },
    {
      title: '已添加微信或QQ',
      dataIndex: 'customers', // 这个后端一会儿用customers一会儿用customer，真是搞死人哟
      width: 200,
      render: (text, record) => {
        const { marketingAccounts } = this.state;
        const {
          wechat_market_account_id: wechat,
          qq_group_market_account_id: qq,
        } = text;
        // 将所有的营销账号转换为下拉选项组件列表
        const options = marketingAccounts
          .filter((item) => {
            // 后端接受任意类型数据的输入以及返回
            // 为了防止不合法数据的显示，前端需要进行过滤
            // 这里应该由后端进行后期调整
            const type = item.account_type.toLowerCase();
            return type === 'qq' || type === '微信';
          })
          .map((item) => {
            return (
              <Option key={item.id}>
                {Tasks.formatMarketAccountDisplay(item)}
              </Option>
            );
          });
        // 设定显示的值
        // 如果用户中绑定了QQ则使用QQ信息显示
        // 若绑定了微信，则使用微信信息显示
        // 否则为空
        // 查找对应的营销账号
        const marketAccount = this.getMarketAccountFromId(wechat || qq);
        const valueData = Tasks.formatMarketAccountDisplay(marketAccount);

        // 返回选项框
        return helpers.isJudge(
          _.includes(this.props.all.main.buttonPermission, 97),
        )(
          <Select
            style={{ width: '150px' }}
            allowClear
            placeholder={'请选择'}
            value={valueData}
            onChange={(value) => {
              const obj: any = {};
              // 查找对应的营销账号
              const account = this.getMarketAccountFromId(parseInt(value, 10));

              // Web并没有返回枚举类型来区别微信和QQ
              // 这个功能经过与Web确认，目前他们没办法修改
              // 所以先用string字段来判断类型区别
              if (account) {
                if (account.account_type.toLowerCase() === 'qq') {
                  obj.qq_group_id = value;
                } else if (account.account_type === '微信') {
                  obj.wechat_id = value;
                }
              } else {
                obj.wechat_id = '';
                obj.qq_group_id = '';
              }
              this.updateCustomersInfo(record.customers.id, obj);
            }}
          >
            {options}
          </Select>,
          null,
        );
      },
    },
    {
      title: '是否已解决',
      dataIndex: '是否已解决',
      width: 200,
      render: (text, record) =>
        helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 98))(
          <Select
            placeholder={'请选择'}
            value={record.customers.is_obstruct}
            onChange={(value) =>
              this.updateBlocked(record.id, {
                obstruct_reason_handled: helpers.isJudge(value === 0)(2, value),
              })
            }
          >
            <Option value={0}>
              <span style={{ color: '#16a085' }}>是</span>
            </Option>
            <Option value={1}>否</Option>
          </Select>,
          null,
        ),
    },
    {
      title: '操作',
      dataIndex: '操作',
      align: 'center',
      width: 100,
      render: (text, record) =>
        helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 99))(
          <span>
            <a
              href={'javascript:;'}
              onClick={() => this.tasksRollback(record.id)}
            >
              退回
            </a>
          </span>,
          null,
        ),
    },
  ];

  /**
   * 通过ID查找对应的营销账号
   * @param id {number}
   * @return {MarketAccountType}
   */
  public getMarketAccountFromId(id: number): MarketAccountType {
    // 因为营销列表属于有序数据
    // 利用有序数数组可二分的特性，加速查找效率
    return binarySearchByKey<MarketAccountType>(
      this.state.marketingAccounts,
      id,
      'id',
    );
  }

  public emptyColumns: any = [
    {
      title: '用户名',
      dataIndex: '用户名',
    },
    {
      title: '真实姓名',
      dataIndex: 'x_real_name',
    },
    {
      title: '手机号码',
      dataIndex: 'x_phone',
    },
    {
      title: '注册时间',
      dataIndex: 'register_time',
    },
    {
      title: '最近存款时间',
      dataIndex: 'last_deposit_time',
    },
    {
      title: '最近提款时间',
      dataIndex: 'last_withdraw_time',
    },
    {
      title: '受阻原因',
      dataIndex: '受阻原因',
    },
    {
      title: '受阻时间',
      dataIndex: 'obstructed_at',
    },
    {
      title: '回访备注',
      dataIndex: '回访备注',
    },
    {
      title: '微信已添加',
      dataIndex: 'wechat_market_account_id',
    },
    {
      title: '是否已解决',
      dataIndex: '是否已解决',
    },
    {
      title: '操作',
      dataIndex: '操作',
    },
  ];

  // 受阻原因数组
  public obstructReasonArray = [
    {
      value: 1,
      text: '存款失败',
    },
    {
      value: 2,
      text: '提款失败/提款不到账',
    },
    {
      value: 3,
      text: '提款失败/银行卡错误',
    },
    {
      value: 4,
      text: '提款失败/流水不足',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      showUserNameSelect: false, // 显示用户名
      customerName: '', // 客户名
      obstruct_id: undefined, // 受阻id
      list: [], // 呼叫中心列表
      marketingAccounts: [], // 营销账户列表
      total: 0, // 总条数,
      num: [], // 被分配数量
      showTimes: 0, // 显示发短信窗口次数
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      extensionState: '-1', // 坐席状态
      page: {
        size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
          10,
          localStorage.getItem('pageSize'),
        ), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
    };
  }

  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    obstructed_at_start: '', // 导入开始日期
    obstructed_at_end: '', // 导入开始日期
    register_time_start: '', // 注册时间范围开始
    register_time_end: '', // 注册时间范围截至
    obstruct_reason: undefined, // 受阻原因
    is_handled: undefined, // 是否已处理
  };

  // 获取呼叫中心四个的分配或领取数
  public getTasksBadgeamount = () => {
    this.props.dispatch({
      type: 'callCenter/getTasksBadgeamountEpic',
      payload: {},
    });
  };

  // 修改客户相关的信息
  public updateCustomersInfo = (id, obj) => {
    this.props.dispatch({
      type: 'customers/updateCustomersEpic',
      payload: {
        id,
        qq_group_id: obj.qq_group_id, // qq群
        wechat_id: obj.wechat_id, // 绑定微信
      },
    });
  };

  // 发送邮件组件
  public SendSmsComponent = multiPlatform.sendSmsMulti(SendSms);

  // 修改客户相关的信息
  public updateBlocked = (id, obj) => {
    this.props.dispatch({
      type: 'callCenter/updateBlockedEpic',
      payload: {
        id,
        obstruct_reason_handled: obj.obstruct_reason_handled,
        obstruct_reason: obj.obstruct_reason,
      },
    });
  };

  public user_id; // 用户保存用户id

  // 呼叫
  public call = (id, obstruct_id) => {
    this.props.dispatch({
      type: 'callCenter/callEpic',
      payload: {
        id,
        tag_id: 29, // 标签id
        obstruct_id, // 受阻id
      },
    });

    // 已处理
    // this.props.dispatch({
    //   type: 'callCenter/dealWithEpic',
    //   payload: {
    //     obstruct_id, // 受阻id
    //   },
    // });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterObj: {
        obstructed_at_start: '', // 导入开始日期
        obstructed_at_end: '', // 导入开始日期
        register_time_start: '', // 注册时间范围开始
        register_time_end: '', // 注册时间范围截至
        obstruct_reason: undefined, // 受阻原因
        is_handled: undefined, // 是否已处理
      },
    });
  };

  public updateTimes = 0; // 被动更新次数

  // 获取呼叫中心列表
  public getBlockedList = () => {
    this.props.dispatch({
      type: 'callCenter/tasksEpic',
      payload: {
        type: window.location.pathname.slice(
          window.location.pathname.lastIndexOf('/') + 1,
        ), // 类型
        tag_id: 29, // 受阻标签id
        register_time_start: this.state.filterObj.register_time_start, // 注册开始时间
        register_time_end: this.state.filterObj.register_time_end, // 注册结束时间
        obstructed_at_start: this.state.filterObj.obstructed_at_start, // 受阻开始时间
        obstructed_at_end: this.state.filterObj.obstructed_at_end, // 受阻结束时间
        obstruct_reason: this.state.filterObj.obstruct_reason, // 受阻原因
        is_handled: helpers.isJudge(this.state.filterObj.is_handled === -1)(
          undefined,
          this.state.filterObj.is_handled,
        ), // 是否已处理
        page_size: this.state.page.size, // 页面显示条数
        page: this.state.page.num, // 页码
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getBlockedList,
    );
  };

  // 通过之前的筛选条件获取列表
  public getListBeforeFilter = () => {
    localStorage.setItem('pageSize', this.state.page.size);
    this.props.dispatch({
      type: 'callCenter/tasksEpic',
      payload: {
        type: window.location.pathname.slice(
          window.location.pathname.lastIndexOf('/') + 1,
        ), // 类型
        tag_id: 29, // 受阻标签id
        register_time_start: this.filterObj.register_time_start, // 注册开始时间
        register_time_end: this.filterObj.register_time_end, // 注册结束时间
        obstructed_at_start: this.filterObj.obstructed_at_start, // 受阻开始时间
        obstructed_at_end: this.filterObj.obstructed_at_end, // 受阻结束时间
        obstruct_reason: this.filterObj.obstruct_reason, // 受阻原因
        is_handled: helpers.isJudge(this.filterObj.is_handled === -1)(
          undefined,
          this.filterObj.is_handled,
        ), // 是否已处理
        page_size: this.state.page.size, // 页面显示条数
        page: this.state.page.num, // 页码
      },
    });
  };

  // 获取营销账号列表
  public getMarketingAccount = () => {
    this.props.dispatch({
      type: 'system/marketingAccounts/getMarketingAccountsEpic',
      payload: {
        pageSize: 1000,
        page: 1,
      },
    });
  };

  // 回退任务
  public tasksRollback = (id) => {
    const self = this;
    confirm({
      title: '您确定要退回名单吗？',
      content: '（退回的名单可在名单库中查看，但不再展示在您的呼叫中心面板中）',
      onOk() {
        self.props.dispatch({
          type: 'callCenter/rollbackTasksEpic',
          payload: {
            id,
          },
        });
      },
      onCancel() {
        //
      },
    });
  };

  // 指定用户名获取呼叫中心列表
  public getBlockedList2 = () => {
    const text = this.state.customerName.trim();
    // 空搜索内容，不进行处理
    if (!text) {
      return;
    }
    localStorage.setItem('pageSize', this.state.page.size);
    this.props.dispatch({
      type: 'callCenter/blockedEpic',
      payload: {
        customer_name: text,
      },
    });
  };

  // 选择注册时间
  public selectRegisterDate = (dateString) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        register_time_start: dateString[0],
        register_time_end: dateString[1],
      },
    });
  };

  // 选择受阻日期
  public selectObstructedDate = (dateString) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        obstructed_at_start: dateString[0],
        obstructed_at_end: dateString[1],
      },
    });
  };

  public componentWillMount() {
    this.getBlockedList();

    this.getTasksBadgeamount(); // 获取呼叫中心四个的分配或领取数

    // 获取营销列表
    if (this.props.all.marketingAccounts.marketingAccounts.length === 0) {
      this.getMarketingAccount();
    }
  }

  // 绑定任务列表
  public componentWillReceiveProps(nextProps) {
    this.setState({
      list: nextProps.state.blockedList,
      total: nextProps.state.total,
      num: nextProps.state.num,
    });

    // 绑定营销列表
    if (
      nextProps.all.marketingAccounts.marketingAccounts.length !== 0 &&
      this.state.marketingAccounts.length === 0
    ) {
      this.setState({
        marketingAccounts: nextProps.all.marketingAccounts.marketingAccounts,
      });
    }

    // 绑定坐席状态
    if (_.get(nextProps.all.agent.agentInfo, 'extensionState') != null) {
      this.setState({
        extensionState: nextProps.all.agent.agentInfo.extensionState,
      });
    }

    // 通过rdux重新获取
    if (nextProps.state.updateTimes !== this.updateTimes) {
      this.updateTimes = nextProps.state.updateTimes;
      this.getListBeforeFilter();
      this.getTasksBadgeamount(); // 获取呼叫中心四个的分配或领取数
    }
  }

  // 过滤渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          {helpers.isJudge(this.state.showUserNameSelect)(
            <span className={'notWrap'} style={{ marginRight: 40 }}>
              用户名：
              <Input
                placeholder={'请输入用户名'}
                style={{ marginRight: 10 }}
                value={this.state.customerName}
                type={'text'}
                onChange={(e) =>
                  this.setState({
                    customerName: e.target.value,
                  })
                }
              />
              {helpers.isJudge(
                _.includes(this.props.all.main.buttonPermission, 96),
              )(
                <Button
                  className={'pBtn'}
                  onClick={() => this.getBlockedList2()}
                >
                  搜索，我要处理
                </Button>,
                null,
              )}
            </span>,
            <span className={'notWrap'} style={{ marginRight: 40 }}>
              <Button
                className={'pBtn'}
                onClick={() =>
                  this.setState({
                    showUserNameSelect: !this.state.showUserNameSelect,
                  })
                }
              >
                <Icon type={'plus'} />
                领取受阻用户
              </Button>
            </span>,
          )}
        </p>
        <p>
          <span className={'notWrap'}>
            受阻时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectObstructedDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.obstructed_at_start === '',
              )(null, [
                moment(this.state.filterObj.obstructed_at_start, dateFormat),
                moment(this.state.filterObj.obstructed_at_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            受阻原因：
            <Select
              allowClear
              placeholder={'请选择'}
              style={{ width: 200 }}
              value={this.state.filterObj.obstruct_reason}
              onChange={(value) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    obstruct_reason: value,
                  },
                })
              }
            >
              {this.obstructReasonArray.map((item) => {
                return <Option value={item.value}>{item.text}</Option>;
              })}
            </Select>
          </span>
          <span className={'notWrap'}>
            注册时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectRegisterDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.register_time_start === '',
              )(null, [
                moment(this.state.filterObj.register_time_start, dateFormat),
                moment(this.state.filterObj.register_time_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            是否已解决：
            <Select
              allowClear
              placeholder={'请选择'}
              value={this.state.filterObj.is_handled}
              onChange={(value) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    is_handled: value,
                  },
                })
              }
            >
              <Option value={-1}>不限</Option>
              <Option value={2}>是</Option>
              <Option value={1}>否</Option>
            </Select>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getListBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
  };

  // 列表模块渲染
  public renderList = () => {
    return (
      <React.Fragment>
        <XList
          columns={helpers.isJudge(this.state.list.length === 0)(
            this.emptyColumns,
            this.columns,
          )}
          dataSource={this.state.list}
          scroll={{ x: 1600 }}
          rowKey={'id'}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'],
          }}
        />
      </React.Fragment>
    );
  };

  public render() {
    const SendSmsComponent = this.SendSmsComponent;
    return (
      <React.Fragment>
        {this.renderFilter()}
        {helpers.isJudge(
          this.state.num.blocked === 0 || this.state.num.blocked === undefined,
        )(
          null,
          <Alert1
            message={`您成功领取但未处理<span>${this.state.num.blocked}</span>条新名单`}
          />,
        )}
        {this.renderList()}
        <SendSmsComponent
          customer_id={this.state.customer_id}
          showTimes={this.state.showTimes}
          obstruct_id={this.state.obstruct_id}
          type={1}
        />
      </React.Fragment>
    );
  }
}
